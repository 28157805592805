// Variable overrides
$primary:       #0e6ba5 !default;
$warning:       #c18715 !default;
$info:          #0e6ba4 !default;
$modal-lg: 		90%;
$modal-xl: 		80%;

//Dark Orange
//#c18816
//#e3ca9b
//#c89836

//Blue
//#0e6ba5
//#68a1c5