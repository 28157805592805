// Here you can add other styles
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.c-body{
	background-color:#fff;
}


.testt{
	max-height:200px
}

.stand-out-box {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.stand-out-box:hover {
  transform: scale(1.03, 1.03);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 2);   
}


.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facility-marker {
  background: none;
  border: none;
}

.facility-marker img {
  width: 20px;
}

.c-sidebar {
  position: absolute!important;
  top: -32px!important;
  left: -26px!important;
  z-index: 1030!important;
}

.c-main {
  background-color: #fff;
  
}

.nav-link {
  font-size: larger;
  color: white!important;
}

.nav-link:hover {
  color: #c18715!important;
}

.c-sidebar {
  z-index: 100!important;
  position: fixed!important;
  top: 96px!important;
  left: 0px!important;
}

.page{
  position:absolute; top:0; left:0; bottom:0; right:0; width:100%; height:100%; 
  border:none; margin:0; padding:0;
}

.iframe{
  position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; 
  border:none; margin:0; padding:0; overflow:hidden; z-index:999999;
}

.adbox{
  background-color:#fff;
  min-height: 350px;
  border: solid 1px #ccc;
  padding:0px;
  border-radius: 25px;
  margin-left: 100px;
  margin-right: 100px;
}

.acc-filter {
  font-size:12px;
  width:320px;
  position:'relative';
  top:-2px;
  display: inline-block;
  color: black;
  height:30px;
  padding-left:30px;
}

.imgcontainer img {
  max-height: 340px;
  height: 100%;
  width: auto;
}

.imgcontainer1 {
  text-align: center;
}

.imgcontainer1 img {
  max-height: 120px;
  height: 100%;
  width: auto;
  border-radius:8px;
}

.imgcontainer2 {
  text-align: center;
}


.imgcontainer2 img {
  max-height: 140px;
  height: 100%;
  width: auto;
  border-radius:8px;
}

.box3d {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.bscusa-header-nav {
  padding-top: 24px;
  padding-left: 550px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .bscusa-header-nav {
    padding-top: 10px;
    padding-left: 10px;
  }
}

.async-select{
  zoom:0.9
}

.simple-select {
  zoom:0.95
}

.location-search-input  {
  border: dotted 1px orange;
  background-color: #fbfbfb;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .table-responsive {
    width:300px
  }
}


.footer-caption {
  color:#fff;
  position:relative;
  bottom:50%;
  left:60%;
}

.footer-title {
  font-size: 32px;
}

.footer-subscribe-link{
  font-size: 18px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .footer-caption {
    bottom:90%;
    left:15%;
    color:#000;
  }

  .footer-title {
    font-size: 18px;
  }
  
  .footer-subscribe-link{
    font-size: 12px;
  }
}

.googlemap {
  height: 100%;
  width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .googlemap {
    height: 600px;
  }
}

.filter-modal {
  width:60%;
  left:20%
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .filter-modal {
    width:72%;
    left:0%
  }
}

.home-caption {
  width:100%; 
  text-align:center;
  color:#fff;
  display: inline-block;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .c-main {
    background-color:#ebedef;
  }
}

.home-image {
  width:110%;
  position:relative;
  top:-32px;
  left:-30px
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .home-image {
    width:113%;
  }
}

.footer-container {
  min-height: 500px;
  background-color:#c18715;
  text-align: center;
}

.footer-image {
  width: 100%;
}

.footer-subscribe {
  width:100%;
  height: 100px;
  position: relative;
  top:-420px
}

.footer-icon {
  width: 120px;
  position : relative;
  top:-60px;
  margin:auto;
}

.footer-links {
  margin-top: -30px;
  height: 200px;
  position : relative;
  top:-150px;
  width: 100%;
  text-align: center;
}

.footer-links .row {
  padding:5px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .footer-container {
    min-height: 280px;
  }

  .footer-subscribe {
    top:0px;
    height: 130px;
  }

  .footer-links {
    height: 170px;
    top:15px;
  }

  .footer-icon {
    width: 70px;
    top:-35px;
  }

  .footer-subscribe-link{
    border: solid 1px white
  }

  
  .c-main{
    background-color: #fff;
  }
  
}

.material-icons {
  vertical-align:bottom;
  color:#666;
}

.mib{
  color:#fff;
  font-size:20px;
}

.bscusa-content{
  min-height: 83vh;
  background-color:#fff
}
